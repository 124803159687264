<!-- Tela que traz os membros da equipe e mostra quem está ativo para esse paciente -->
<template>
  <div class="px-4 pb-4">
    <v-row dense>
      <!-- {{patient.endereco.municipio}} -->
      <v-col>
        <v-data-table :loading="isLoading('get')" loading-text="Carregando Informações...." :search="search" :items="tableData" :headers="headers" dense
          v-model="selectedRows" show-select checkbox-color="green" >
          <template v-slot:top>
            <v-row dense>
              <v-col>
                <v-text-field dense outlined v-model="search" label="Pesquisar" class="mx-0 mt-2" clearable ></v-text-field>
              </v-col>
              <v-col class="text-end mt-2">
                <v-btn class="mr-4 elevation-0" text  @click="atualizarDados"> <v-icon left> mdi-reload </v-icon>  atualizar página </v-btn>
              </v-col>

            </v-row>
            <p>OBS: A zona da residência do paciente é <b>" {{nomeZonaPaciente}} ".</b></p>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-end">
        <v-btn :loading="isLoading('put')" color="success" @click="updateEquip">Salvar</v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import api from '@/http'
import UtilsFunc from '@/service/utilsFunc'
import { mapGetters } from 'vuex'

const { withCRUDUtils } = UtilsFunc

export default {
  name: "EquipeMultidiciplinar",
  props: {
    paciente: {
      type: Object
    },
    onUpdate: Function
  },
  computed: {
    ...mapGetters(['companyData']),
    nomeZonaPaciente() {
      const zonaPaciente = this.zonas.find(zona => zona.id === this.paciente.zona);
      return zonaPaciente ? zonaPaciente.nome : 'Zona não encontrada';
    },
  },
  data: () => withCRUDUtils({
    patient: {},
    zonas: [],
    headers: [
      { text: "Id", value: "id", width: 80 },
      { text: "Nome", value: "apelido", width: 250 },
      { text: "Nome completo", value: "nome", width: 400 },
      { text: "Especialidade", value: "especialidade.nome" },
      { text: "Zona de atendimento", value: "zonaNome" }
    ],
    selectedRows: [],
  }),
  methods: {
    async getEquip() {
      const LOADING_NAME = 'get'
      this.setLoading(LOADING_NAME)
      try {
        const { data } = await api.get(`contas/equipe-multidisciplinar/`)
        // console.log(data)
        // const zonaPacienteId = this.paciente.zona; 
        this.selectedRows = this.patient.equipe_multi_disciplinar
        const municipioPacienteId = this.patient.endereco.municipio;
        const lista = data
          .map(e => {
            // Se não tem o município correspondente, retorna null para filtrar fora
            const temMunicipioCorrespondente = e.tabelaValorMunicipio.includes(municipioPacienteId);
            if (!temMunicipioCorrespondente) return null;
            // Filtra apenas as zonas ativas do membro da equipe
            const zonasAtivas = e.zona.filter(z => z.ativo);
            // Verifica se o membro da equipe tem alguma zona que corresponde à zona do paciente 
            // const temZonaCorrespondente = zonasAtivas.some(z => z.id === zonaPacienteId); 
            // if (!temZonaCorrespondente) return null; 

            // Prepara o objeto do membro da equipe para renderização
            var id = this.selectedRows.filter(i => i.id === e.id).length > 0;
            e['ordenar'] = id;
            if (e.is_active == true) e['zonaNome'] = zonasAtivas.map(z => z.nome).join(', ');
            return e;
        }).filter(Boolean)
        // console.log(this.tableData)
        this.tableData = lista.sort((a, b) => {
          // Primeiro, compare os valores booleanos (true vem antes de false)
          if (a.ordenar && !b.ordenar) {
            return -1;
          }
          if (!a.ordenar && b.ordenar) {
            return 1;
          }
          // Se os valores booleanos forem iguais, compare as especialidades
        const especialidadeA = a.especialidade?.nome;
        const especialidadeB = b.especialidade?.nome;
        if (especialidadeA !== undefined && especialidadeB !== undefined)
          return especialidadeA.localeCompare(especialidadeB);
        }).filter(Boolean);
      } catch (error) {
        // this.$errorHandler(error)
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async updateEquip() {
      const LOADING_NAME = 'put'
      this.setLoading(LOADING_NAME)
      try {
        this.paciente.equipe_multi_disciplinar = this.selectedRows.map(row => row.id)
        const patient = { ...this.paciente, ativo: true }

        await api.put(`pacientes/paciente/${patient.id}/`, patient)
        await this.getPatientEquip()
        await this.getEquip()
        this.onUpdate()
        this.$toast.success("Equipe Atualizada com Sucesso!")
      } catch (error) {
        this.$errorHandler(error)
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async getPatientEquip() {
      const LOADING_NAME = 'get'
      this.setLoading(LOADING_NAME)
      try {
      const { data } = await api.get(`/pacientes/detalhes/?paciente=${this.paciente.id}&empresa=${this.companyData.id}`)
      this.patient = data[0] || {}
      // console.log(this.patient)
      } catch (error) {
        // this.$errorHandler(error)
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async getZonas(){
      const { data } = await api('contas/zonas/')
      this.zonas = data.filter(zona => zona.ativo);
      // console.log(this.zonas)
    },
      async atualizarDados() {
        // Supondo que você tenha métodos para atualizar os dados necessários
        await this.getPatientEquip(); // Carrega detalhes específicos do paciente
        await this.getEquip(); // Carrega membros da equipe disponíveis/atualizados
      },
  },
  mounted() {
    this.getZonas();
    (async () => {
      await this.getPatientEquip()
      this.getEquip()
    })()
  },
}
</script>

<style>
div.v-simple-checkbox i.v-icon.mdi-checkbox-marked {
  color: #4caf50
}
</style>
