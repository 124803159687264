<template>
  <v-container style="padding: 0;">
    <v-btn :disabled="loading || !selectedBudgets.length" color="success" depressed size="small" @click="getPDF()"
      :loading="loading">
      <v-icon left>mdi-printer</v-icon> Avaliação Domicílio
    </v-btn>
  </v-container>
</template>
<script>
import api from "@/http";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import UtilsPDF from '../../../service/utilsPDF';
import UtilsFunc from "../../../service/utilsFunc";
import logo from "../../../assets/logo.png";
import gptw from "../../../assets/GPTW_2023-2024.png";
import ona from "../../../assets/ONA_Nivel_2.png";

const { withCRUDUtils } = UtilsFunc
export default {
  name: "Domicilio",
  data: () => withCRUDUtils({
    loading: false,
    domicilio: [],
    assinaturas: [],
  }),
  props: {
    selectedBudgets: {
      type: Array,
      default: () => []
    },
    username: String
  },
  methods: {
    async getPDF() {
      this.loading = true;
      this.$toast.success(
        `O documento está sendo preparado. Isso pode levar alguns instantes. Aguarde, por favor.`
      );
      try {
        await Promise.all([
          this.getDomicilio()
        ]);
        this.savePDF();
        this.loading = false;
      } catch (error) {
        this.$toast.error("Erro ao carregar os dados:", error);
        this.loading = false;
      }
    },
    async download(dataurl, filename) {
      const httpsurl = !dataurl.includes("https") ? dataurl.replace("http", "https") : dataurl
      const res = await fetch(httpsurl)
      const buffer = await res.blob()
      const url = URL.createObjectURL(buffer)
      const link = document.createElement("a");
      link.download = filename;
      link.href = url;
      link.click();
    },
    async savePDF() {
      const domicilio = this.domicilio[0];
      if (!domicilio || !domicilio.timestamp) {
    this.$toast.error('Erro: Dados do domicílio estão ausentes ou incompletos.');
    return;
  }
      const periodoOrcamento = `${UtilsPDF.formatDateToFilename(domicilio.timestamp)}`;
      const filename = `AVALIACAO_DOMICILIAR_${this.selectedBudgets[0]?.nome.replace(/\s+/g, '_')}_${this.selectedBudgets[0]?.operadora?.nome.replace(/\s+/g, '_')}_Avaliado_em_ ${periodoOrcamento}.pdf`;

      await this.generatePDF();

      const pdfBuffer = this.doc.output('arraybuffer')
      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" })
      const formData = new FormData()
      formData.append("file", pdfBlob, filename)
      formData.append("filename", filename);
      const { data: signedForm } = await api.post('multi_empresas/sign-pdf/', formData)

       // Código para salbvar no doc do paciente 
      const addHttps = signedForm.path.replace('http://', 'https://')
      const signedPDFBlob = await fetch(addHttps).then(r => r.blob())
      await this.saveDocument(signedPDFBlob, filename);

      const signedPdfUrl = signedForm.path

      this.download(signedPdfUrl, filename)
      this.$toast.success('O download do relatório foi realizado com sucesso!');
    },
    // Fórmula para salvar automaticamente o doc nos docs do paciente
    async saveDocument(pdfBlob, filename) {
      const today = new Date().toISOString().split('T')[0];
      const formData = new FormData();
      formData.append("arquivo", pdfBlob, filename);
      formData.append("paciente", this.domicilio[0].paciente.id);
      formData.append("tipodoc", 34);
      formData.append("data_carregado", today);
      try {
        await api.post('pacientes/docs/', formData);
        this.$toast.success('O relatório foi salvo nos documentos do paciente.');
      } catch (error) {
        this.$toast.error('Erro ao salvar o relatório nos documentos do paciente.', error);
      }
    },
     drawHeader(doc) {
      // const orcamento = this.orcamento
      const domicilio = this.domicilio[0]
      try {
        doc.addImage(logo, 10, 8, 28, 28);
        doc.addImage(ona, 160, 10, 15, 15);
        doc.addImage(gptw, 185, 10, 15, 15);
      } catch (error) {
        this.$toast.error("Falha ao adicionar a imagem ao PDF:", error);
      }
      doc.setFont("helvetica", "normal");
      doc.setDrawColor(57, 106, 159);
      doc.setLineWidth(1);
      doc.line(5, 5, 205, 5);

      doc.setLineWidth(0.5);
      doc.roundedRect(5, 40, 200, 18, 3, 3, "S");

      doc.setDrawColor(0, 0, 0);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(18);
      doc.text("São Luís Home Care", 105, 15, { align: "center" });
      doc.setFont("helvetica", "normal");
      doc.setFontSize(16);
      doc.text(`Ficha de Avaliação do Domicílio`, 105, 23, { align: "center" });

      moment.locale("pt-br");

      const currentDate = new Date();
      const hour = moment(currentDate).format("HH:mm");
      const xFixedPosition = 10;
      const yFixedPosition = 68;
      const lineHeight = 6
      const pageWidth = 210;

      doc.setFontSize(8);
      doc.text(`Gerado por: ${this.username}`, 200, 28, { align: "right"});
      doc.text("Data e Hora da Impressão:", 200, 32, { align: "right" });
      doc.text(`${UtilsPDF.formatDate(currentDate)} às ${hour}`, 200, 36, { align: "right", });
      doc.text(`FORM: 129`, 200, 39, { align: "right", });

      doc.setFontSize(11);
      if (domicilio && domicilio.timestamp) {
            doc.text(`Avaliado em: ${UtilsPDF.formatDate(domicilio.timestamp)}`, 81, 32);
        } else {
            doc.text("Avaliado em: Não disponível", 81, 32);
        }

      let yPosition = 46
      doc.text( `Paciente: ${this.selectedBudgets[0].nome}`, xFixedPosition, yPosition );

      const dataNascimento = this.selectedBudgets[0].data_nascimento ? `Data Nascimento: ${UtilsPDF.formatDate(this.selectedBudgets[0].data_nascimento)}`  : 'Data Nascimento: ';

      doc.text( dataNascimento,  200,  yPosition,  { align: "right" } );

      yPosition += lineHeight
      doc.text(`Operadora: ${this.selectedBudgets[0].operadora.nome}`, xFixedPosition, yPosition);

      doc.text(`Nº da Carteira: ${this.selectedBudgets[0].nr_carteirinha ? this.selectedBudgets[0].nr_carteirinha : ''}`, 200, yPosition, {  align: "right", });

      yPosition += lineHeight

      // yPosition += lineHeight * 2
      doc.setFontSize(16);
      doc.setFont("helvetica", "bold");
      const textWidth = doc.getTextWidth("Avaliação do Domicílio");
      const xCenteredPosition = pageWidth / 2 - textWidth / 2;
      doc.text("Avaliação do Domicílio", xCenteredPosition, yFixedPosition);
      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");
    },
    async generatePDF() {
      const doc = new jsPDF({ compress: true });
      this.drawHeader(doc);
      const pageWidth = doc.internal.pageSize.getWidth();
      let yPosition = 80;
      const data = this.domicilio;
      // console.log(data)
      const xOffset = 15;  // Adiciona um deslocamento para a direita

      // Ordena os dados pelo timestamp, do mais antigo para o mais recente
      data.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

      // Mapeia as variáveis booleanas do objeto casa para frases específicas no formato de formulário
      const booleanToPhraseMap = {
        estrutura: 'Estrutura em bom estado (paredes, piso e teto):',
        ambiente: 'Ambiente higienizado:',
        banheiro_proximo: 'Banheiro próximo ao quarto:',
        banheiro_quarto: 'Banheiro dentro do quarto:',
        ventilacao: 'Boa ventilação:',
        ar_condicionado: 'Ar condicionado:',
        ventilador: 'Ventilador:',
        acesso: 'Fácil acesso:',
        tomada: 'Tomadas próximo à cama:',
        poltrona: 'Poltrona ou cama para técnicas:',
        mudanca: 'Precisa de alguma mudança no quarto:',
        reponsavel: 'Responsável presente na avaliação:',
        cuidador: 'Disponibilidade para cuidar:',
        envolvimento: 'Envolvimento com o processo de adoecimento:',
        conflito: 'Conflitos familiares:',
        ausente: 'Ausentes:'
      };
      // };
// Função para desenhar um título alinhado à esquerda com fundo azul
const drawSectionTitle = (title) => {
    const xPosition = 15;  // Alinha o texto à esquerda com um pequeno espaço da borda
    const backgroundHeight = 8;
    const titleYOffset = yPosition + 2;  // Ajuste para centralizar verticalmente o texto dentro do fundo

    doc.setFillColor(57, 106, 159); // Azul
    doc.rect(10, yPosition - 3, pageWidth - 20, backgroundHeight, 'F'); // Ajusta a posição e altura do fundo

    doc.setFontSize(10); // Tamanho da fonte do título
    doc.setTextColor(255, 255, 255); // Texto branco
    doc.setFont("helvetica", "bold");
    doc.text(title, xPosition, titleYOffset);  // Alinha o texto à esquerda
    yPosition += backgroundHeight + 4; // Ajusta o espaço após o título
};

      // Primeira Seção: Tipo de Residência
      drawSectionTitle("TIPO DE RESIDÊNCIA:");
      
      data.forEach((item) => {
        const casaData = item.casa;

        // Primeira linha para "Casa" ou "Apartamento"
        const residencia = casaData.casa ? 'Casa ( X )    Apartamento (     )' : 'Casa (     )    Apartamento ( X )';
        doc.setFont("helvetica", "normal");
        doc.setTextColor(0,0,0); // Texto preto
        doc.setFontSize(9); // Reduz o tamanho da fonte para 9
        doc.text(`Residência: ${residencia}`, xOffset, yPosition);
        yPosition += 8; // Ajusta a posição vertical para a próxima linha

        // Segunda Seção: Estrutura
        drawSectionTitle("ESTRUTURA:");

        // Processa as variáveis relacionadas à estrutura
        for (const key of ['estrutura', 'ambiente', 'banheiro_proximo', 'banheiro_quarto', 'ventilacao', 'ar_condicionado', 'ventilador', 'acesso', 'tomada', 'poltrona', 'mudanca']) {
          if (booleanToPhraseMap[key]) {
            const response = casaData[key] ? 'Sim ( X )    Não (     )' : 'Sim (     )    Não ( X )';
            doc.setFont("helvetica", "normal");
            doc.setFontSize(9); // Reduz o tamanho da fonte para 9
            doc.setTextColor(0,0,0); // Texto preto
            doc.text(`${booleanToPhraseMap[key]} ${response}`, xOffset, yPosition);
            yPosition += 8; // Ajusta a posição vertical para a próxima linha
          }
        }

        // Terceira Seção: Impressões Familiares Iniciais
        drawSectionTitle("IMPRESSÕES FAMILIARES INICIAIS:");

        // Processa as variáveis relacionadas às impressões familiares iniciais
        for (const key of ['reponsavel', 'cuidador', 'envolvimento', 'conflito', 'ausente']) {
          if (booleanToPhraseMap[key]) {
            const response = casaData[key] ? 'Sim ( X )    Não (     )' : 'Sim (     )    Não ( X )';
            doc.setFont("helvetica", "normal");
            doc.setTextColor(0,0,0); // Texto preto
            doc.setFontSize(9); // Reduz o tamanho da fonte para 9
            doc.text(`${booleanToPhraseMap[key]} ${response}`, xOffset, yPosition);
            yPosition += 8; // Ajusta a posição vertical para a próxima linha
          }
        }

        // Adicionar imagem do carimbo e nome do profissional
        const profissional = item.profissional;
        const especialidade = item.especialidade;
        if (profissional) {
          const xCarimboPosition = pageWidth - 40;  // Posição X para a imagem e texto do carimbo
          let carimboImageURL = profissional.carimbo;
          const params = profissional.carimbo.split('://')
            if (params[0] === 'http' && params[1] !== 'localhost') {
              carimboImageURL = carimboImageURL.replace('http', 'https')
            }

          if (carimboImageURL) {
              // Adiciona a imagem do carimbo se disponível
              doc.addImage(carimboImageURL, 'JPEG', xCarimboPosition, yPosition +2, 25, 10); // Tamanho e posição ajustados
          }
          // Adiciona o nome do profissional abaixo da imagem do carimbo
          doc.setFontSize(8);
          doc.text(`${profissional.apelido} - ${especialidade.nome}`, xCarimboPosition + 12, yPosition + 18, { align: 'center' });
      }
      });

      UtilsPDF.addPageNumbers(doc);
      this.doc = doc;
    },
      async getDomicilio() {
      try {
        const { data } = await api.get(`atendimentos/app-evolucoes-nead_abemid/?paciente_id=${this.selectedBudgets[0].id}&casa=true`);
        const casaDataWithValues = data.filter(item => item.casa && Object.keys(item.casa).length > 0);

        if (casaDataWithValues.length > 0) {
          const latestcasaData = casaDataWithValues.reduce((latest, current) => {
            return new Date(current.timestamp) > new Date(latest.timestamp) ? current : latest;
          });

          this.domicilio  = [latestcasaData];
          // console.log(this.domicilio)
        } else {
          this.domicilio[0] = null;
        }
        // console.log("Casa Data:", this.casa); 
      } catch (error) {
        // this.$toast.error("Erro ao buscar os dados da Captação:", error);
      }
    },
  },
};
</script>