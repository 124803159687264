<template>
  <v-row v-if="isLoading" class="d-flex justify-center py-8">
    <v-progress-circular indeterminate />
  </v-row>
  <v-row v-else dense>
    <!-- Coluna da equipe que está ativo para esse paciente -->
    <v-col>
      <h4>
        <b>Equipe Multidisciplinar:</b>
        <span v-if="patient.equipe_multi_disciplinar.length === 0" class="text"> Sem Equipe Cadastrada</span>
      </h4>
      <p v-if="patient.equipe_multi_disciplinar.length > 0"></p>
      <span v-for="i in patient.equipe_multi_disciplinar" :key="i.id">
        <p>ID: {{ i.id }} -> {{ i.apelido }} - ({{ i.especialidade ? i.especialidade.nome : 'Falta cadastrar a especialidade. :/' }})</p>
      </span>
    </v-col>
    <!-- Coluna do plano terapeutico ativo (hoje) para esse paciente e seus equipamentos-->
    <v-col>
      <h4>
        <b>Plano Terapêutico:  </b> 
          <span v-if="validadePlanoTerapeuticoVigente"> <b> ->  Válido até :</b> {{ formatDate(validadePlanoTerapeuticoVigente) }} <p></p></span>
          <span v-else class="text"> Sem Orçamento Aprovado</span>
      </h4>
      <p v-for="i in patient.plano_terapeutico" :key="i.id"> {{ i.procedimento__procedimento__nome }} - {{ i.frequencia__nome }} </p>
      <p></p>
      <b>* ------- * ------- * ------- * ------- * ------- * 
      <p></p>
      </b>
      <h4>
        <b>Equipamentos:</b>
        <span v-if="equipamento.length === 0" class="text"> Sem Equipamento Cadastrado</span>
      </h4>
      <p v-if="equipamento.length > 0"></p>
      <span v-for="i in equipamento" :key="i.id">
        <p>  {{ i.nome_equipamento.nome }} - Status: {{ i.disponibilidade.nome}} </p>
      </span>
      
    </v-col>
    
    <!-- Coluna cobertura da Operadora do Paciente -->
    <v-col>
      <h4> 
        <p>Pacote:
          <span v-if="!nomedopacote" class="text" > <b> Sem Orçamento Aprovado </b> </span>
          <span v-if="nomedopacote"> ->  {{ nomedopacote }}</span> 
        </p>
      </h4>
        <div v-if="itensDoPacote.length === 0">
          <span v-if="validadePlanoTerapeuticoVigente" class="text"> <b>Sem itens cadastrados nesse pacote </b> </span>
        </div>
      <div v-for="item in itensDoPacote" :key="item.id" > <p></p>
        <p>{{ getItemNome(item) }} - Ilimitado: {{ item.ilimitado ? 'Sim' : 'Não' }} <span v-if="!item.ilimitado">- Qtd.: {{ item.quantidade || 'N/A' }}</span></p>
      </div>
    </v-col>
    <!-- Coluna com os dados Pessoais do paciente -->
    <v-col>
      <!-- {{pps}} -->
      <h4><b>Dados Pessoais:</b></h4><p></p>
      <p v-if="casa"><b>Avaliação do Domicílio: </b> Realizada em {{ formatDate(casa.timestamp.split('T')[0]) }}</p> <p v-else> <b>Avaliação do Domicílio: </b><span style="color: red;"> Não realizada</span> </p> 
      <p v-if="captacao"><b>Captação: </b> Realizada em {{ formatDate(captacao.captacao.data_avaliacao.split('T')[0]) }}</p> <p v-else> <b>Captação: </b><span style="color: red;"> Não realizada </span> </p> 
      <p v-if="nead"><b>NEAD:</b> {{ neadDescription }} </p> <p v-else> <b>NEAD:</b> <span style="color: red;">Não realizado</span></p>
      <p v-if="abemid"><b>ABEMID:</b> {{ abemidDescription }} </p> <p v-else>  <b>ABEMID:</b> <span style="color: red;">Não realizado</span> </p>
      <p v-if="pps"> <b>PPS: </b> {{ ppsPontuacao }} - {{ ppsDescription }} </p> <p v-else> <b>PPS: </b> <span style="color: red;"> Não realizado</span> </p>
      <p v-if="patient.alergia !== null"><b>Alergia: </b> {{ patient.alergia }}</p> <p v-else> <b> Alergia:</b> Nega Alergia </p>
      <p v-if="patient.sexo_biologico"> <b>Sexo Biológico: </b> {{ patient.sexo_biologico }} </p>  <p v-else> <b>Sexo Biológico: </b> <span style="color: red;"> Não cadastrado</span></p>
      <p v-if="patient.tiposanguineo"> <b>Tipo Sanguíneo: </b> {{ patient.tiposanguineo.nome }} </p> <p v-else> <b>Tipo Sanguíneo:</b> <span style="color: red;"> Não cadastrado</span></p>
      <p v-if="patient.altura"> <b>Altura: </b> {{ patient.altura }} </p> <p v-else> <b>Altura:</b> <span style="color: red;"> Não cadastrado</span></p>
      <p v-if="patient.peso"> <b>Peso: </b> {{ patient.peso }} </p> <p v-else> <b>Peso:</b> <span style="color: red;"> Não cadastrado</span></p>
      <p v-if="patient.religiao"> <b>Religiao: </b> {{ patient.religiao }} </p> <p v-else> <b>Religiao:</b> <span style="color: red;"> Não cadastrado</span></p>
      <p v-if="patient.profissao"> <b>Profissão: </b> {{ patient.profissao }} </p> <p v-else> <b>Profissão:</b> <span style="color: red;"> Não cadastrado</span></p>
      <p v-if="patient.estado_civil"> <b>Estado Civil: </b> {{ patient.estado_civil }} </p> <p v-else> <b>Estado Civil:</b> <span style="color: red;"> Não cadastrado</span></p>
      <p v-if="patient.etinia"> <b>Etinia: </b> {{ patient.etinia }} </p> <p v-else> <b>Etinia:</b> <span style="color: red;"> Não cadastrado</span></p>
      <p v-if="patient.cid"> <b>CID: </b> {{ patient.cid.nome }} -> {{ patient.cid.descricao }} </p><p v-else> <b>CID: </b> <span style="color: red;"> Não cadastrado</span></p>
      <p v-if="patient.medico"> <b>Médico Responsável: Dr.(a): </b> {{ patient.medico.apelido }} </p><p v-else> <b>Médico Responsável: Dr.(a):</b> <span style="color: red;"> Não cadastrado</span></p>
      <p><b>Usa Respirador: </b> {{ patient.respirador === true ? 'SIM' : patient.respirador === false ? 'NÃO' : '' }}</p>
      <p v-if="patient.responsavel"> <b>Responsável pelo Paciente:</b> {{ patient.responsavel }} </p><p v-else> <b>Responsável pelo Paciente:</b> <span style="color: red;"> Não cadastrado</span></p>
      <p v-if="patient.telefone" > <b>Telefone do Paciente:</b> {{ formatPhone(patient.telefone) }} </p><p v-else> <b>Telefone do Paciente::</b><span style="color: red ;"> Não cadastrado</span> </p>
      <p v-if="patient.endereco"> <b>Endereço:</b> 
        {{ patient.endereco.logradouro}} -  
        Nrº {{ patient.endereco.nr}} -  
        Bairro: {{ patient.endereco.bairro}} -  
        Complemento: {{ patient.endereco.complemento}} -  
        {{ patient.endereco.cidade}} / 
        {{ patient.endereco.estado}} -
        CEP: {{ patient.endereco.cep}}
      </p><p v-else> <b>Endereço:</b> </p>
      <p><b>Zona de Residência: </b> {{ patient.zona ? patient.zona.nome : ''}} </p>
      <p v-if="patient.email"> <b>E-mail do Paciente:</b> 
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <a :href="`mailto:${patient.email}`" v-bind="attrs" v-on="on">  {{ patient.email ? patient.email  : ''}} </a>
            <v-icon v-if="patient.email" color="blue">mdi-email</v-icon>
          </template>
          <span>Enviar Email</span>
        </v-tooltip> 
      </p><p v-else> <b>E-mail do Paciente:</b><span style="color: red ;"> Não cadastrado</span> </p> 
      <p v-if="patient.rg"> <b>RG: </b> {{ patient.rg }} </p><p v-else> <b>RG:</b><span style="color: red ;"> Não cadastrado</span> </p>
      <p v-if="patient.data_atendimento"> <b>Data início atendimento: </b> {{ formatDate(patient.data_atendimento) }} </p><p v-else> <b>Data início atendimento:</b> <span style="color: red;"> Não cadastrado</span></p>
      <p v-if="patient.plano"> <b>Plano da Operadora: </b> {{ patient.plano.nome }} </p><p v-else> <b>Plano da Operadora:</b> <span style="color: red;"> Não cadastrado</span></p>
      <p v-if="patient.nr_carteirinha"> <b>Nº Carteirinha: </b> {{ patient.nr_carteirinha}} </p><p v-else> <b>Nº Carteirinha:</b><span style="color: red ;"> Não cadastrado</span> </p>
      <p v-if="patient.validade"> <b>Validade: </b> {{ patient.validade | formatData}} </p><p v-else> <b>Validade:</b><span style="color: red ;"> Não cadastrado</span> </p>
    </v-col>

  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import api from "../../../../http";

export default {
  name: "PatientExpand",
  data: () => ({
    isLoading: true,
    patient: {},
    equipamento :[],
    pacote:[],
    pacoteId: [],
    pps:null,
    abemid: null,
    captacao: null,
    casa: null,
    nead: null,
    nomedopacote: [],
    itensDoPacote: [],
    ppsDescription: "",
    ppsPontuacao: "",
    pontuacaoTotal: "",
    pontuacaoTotalNead:"",
  }),
  props: {
    item: Object,
    modalidade_id: Number,
  },
  computed: {
    ...mapGetters(['companyData']),
    validadePlanoTerapeuticoVigente() {
    if (this.patient.plano_terapeutico && this.patient.plano_terapeutico.length > 0) {
      return this.patient.plano_terapeutico[0].sessao__data_fim;
    }
    return null;
  }
  },
  methods: {
    async getPatientDetails() {
      this.isLoading = true;
      const { data } = await api.get(
        `/pacientes/detalhes/?paciente=${this.item.id}&empresa=${this.companyData.id}&ativo=${Number(this.item.ativo)}`
      );
      this.patient = data[0]
      // console.log(this.patient)
      this.patient.equipe_multi_disciplinar = this.patient.equipe_multi_disciplinar.map(e=>{
        if (e.is_active===true) return e
      }).filter(Boolean)
      this.isLoading = false;
    },
    async getPatientEquipamento() {
      const { data } = await api.get( `/pacientes/equipamentos/?paciente=${this.item.id}`);
      this.equipamento = data
          .filter(item => item.ativo)
          .sort((a, b) => {
            if (a.nome_equipamento.nome < b.nome_equipamento.nome) return -1;
            if (a.nome_equipamento.nome > b.nome_equipamento.nome) return 1;
            return 0;
        });
      // console.log(this.equipamento)
    },
    async getPacote() {
      this.isLoading = true;
      // console.log(this.modalidade_id);
      try {
        const { data } = await api.get(`/operadoras/operadora-pacotes/?operadora=${this.patient.operadora.id}`);
        const pacote = data.find(item => item.ativo && item.modalidade.id === this.modalidade_id);

        if (pacote) {
          this.pacoteId = pacote.id;
          this.nomedopacote = pacote.nome
          // console.log('Pacote ID --> ', this.nomedopacote);
        } else {
          this.pacoteId = null;
          this.nomedopacote = null;
          // console.log('Nenhum pacote ativo encontrado com a modalidade especificada.');
        }
      } catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado ao buscar os itens do pacote. Deu erro ${error}`);
      } finally {
        this.isLoading = false;
      }
    },
    async getItensDoPacote() {
       this.isLoading = true;
      try {
        const { data } = await api.get(`/operadoras/operadora-itempacotes/?operadora=${this.patient.operadora.id}&pacote=${this.pacoteId}`);
        this.itensDoPacote = data.filter(item => item.ativo)
        // console.log('Itens do pacote --> ',  this.itensDoPacote);
      } catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado ao buscar os itens do pacote. Deu erro ${error}`);
      } finally {
        this.isLoading = false;
      }
    },
    async getPps() {
      try {
        const { data } = await api.get(`atendimentos/app-evolucoes-nead_abemid/?paciente_id=${this.item.id}&pps=true`);
        const ppsDataWithValues = data.filter(item => item.pps && Object.keys(item.pps).length > 0);
        if (ppsDataWithValues.length > 0) {
          const latestPpsData = ppsDataWithValues.reduce((latest, current) => {
            return new Date(current.timestamp) > new Date(latest.timestamp) ? current : latest;
          });

          this.pps = latestPpsData;
          const pontos = this.pps.pps;
          const pontuacoes = [
              pontos.dambulacao_PPS.pontuacao,
              pontos.atividade_PPS.pontuacao,
              pontos.auto_cuidado_PPS.pontuacao,
              pontos.ingestao_PPS.pontuacao,
              pontos.nivel_conciencia_PPS.pontuacao
          ].filter(value => value !== null && value !== undefined);

          const ppsPontuacao = Math.min(...pontuacoes);
          // console.log('Ponto', ppsPontuacao)
          this.ppsDescription = this.getPpsDescription(ppsPontuacao);
          this.ppsPontuacao = `${ppsPontuacao}%`
        } else {
          this.pps[0] = null;
        }
      } catch (error) {
        // this.$toast.error("Erro ao buscar os dados do PPS:", error);
      }
    },
    async getCaptacao() {
    try {
        const { data } = await api.get(`atendimentos/app-evolucoes-nead_abemid/?paciente_id=${this.item.id}&captacao=true`);
        
        // Filtra os itens que têm uma captação válida
        const captacaoDataWithValues = data.filter(item => item.captacao && Object.keys(item.captacao).length > 0);

        if (captacaoDataWithValues.length > 0) {
            // Encontra o item com a maior data de avaliação
            const latestCaptacaoData = captacaoDataWithValues.reduce((latest, current) => {
                return new Date(current.captacao.data_avaliacao) > new Date(latest.captacao.data_avaliacao) ? current : latest;
            });

            this.captacao = latestCaptacaoData;
        } else {
            this.captacao = null;
        }
        // console.log("Captacao Data:", this.captacao);
    } catch (error) {
        console.error("Erro ao buscar os dados da Captação:", error);
    }
    },
    async getCasa() {
      try {
        const { data } = await api.get(`atendimentos/app-evolucoes-nead_abemid/?paciente_id=${this.item.id}&casa=true`);
        const casaDataWithValues = data.filter(item => item.casa && Object.keys(item.casa).length > 0);

        if (casaDataWithValues.length > 0) {
          const latestcasaData = casaDataWithValues.reduce((latest, current) => {
            return new Date(current.timestamp) > new Date(latest.timestamp) ? current : latest;
          });

          this.casa  = latestcasaData;
        } else {
          this.casa[0] = null;
        }
        // console.log("Casa Data:", this.casa); 
      } catch (error) {
        // this.$toast.error("Erro ao buscar os dados da Captação:", error);
      }
    },
    async getAbemid() {
      try {
        const { data } = await api.get(`atendimentos/app-evolucoes-nead_abemid/?paciente_id=${this.item.id}&abemid=true`);
        const abemidDataWithValues = data.filter(item => item.abemid && Object.keys(item.abemid).length > 0);

        if (abemidDataWithValues.length > 0) {
          const latestAbemidData = abemidDataWithValues.reduce((latest, current) => {
            return new Date(current.timestamp) > new Date(latest.timestamp) ? current : latest;
          });

          this.abemid = latestAbemidData;

          // Calcula a soma das pontuações ignorando abemid.pontuacao
          const pontuacoes = [
            this.abemid.abemid.suporte_terapeutico.pontuacao,
            this.abemid.abemid.quimioterapia.pontuacao,
            this.abemid.abemid.suporte_ventilatorio.pontuacao,
            this.abemid.abemid.lesao.pontuacao,
            this.abemid.abemid.grau_atividade.pontuacao,
            this.abemid.abemid.dependencia.pontuacao,
            this.abemid.abemid.terapia_nutricional.pontuacao,
          ];

          const pontuacaoTotal = pontuacoes.reduce((sum, value) => sum + value, 0);
          const countFivePoints = pontuacoes.filter(p => p === 5).length;

          // Determina a complexidade baseada nas novas regras
          if (countFivePoints >= 2) {
            this.abemidDescription = "Alta Complexidade";
          } else if (countFivePoints === 1 || pontuacaoTotal >= 13) {
            this.abemidDescription = "Média Complexidade";
          } else if (pontuacaoTotal >= 8 && pontuacaoTotal <= 12) {
            this.abemidDescription = "Baixa Complexidade";
          } else {
            this.abemidDescription = "Paciente não elegível para Internação Domiciliar";
          }
          this.pontuacaoTotal = `${pontuacaoTotal}`
        } else {
          this.abemid = null;
          this.abemidDescription = "Não realizado";
        }
      } catch (error) {
        console.error("Erro ao buscar os dados da Abemid:", error);
      }
    },
    async getNead() {
      try {
        const { data } = await api.get(`atendimentos/app-evolucoes-nead_abemid/?paciente_id=${this.item.id}&nead=true`);
        const neadDataWithValues = data.filter(item => item.nead && Object.keys(item.nead).length > 0);

        if (neadDataWithValues.length > 0) {
          const latestneadData = neadDataWithValues.reduce((latest, current) => {
            return new Date(current.timestamp) > new Date(latest.timestamp) ? current : latest;
          });

          this.nead = latestneadData;

          // Primeira verificação: cuidador, domicio_risco, deslocamento
          if (!this.nead.nead.cuidador || !this.nead.nead.domicio_risco || !this.nead.nead.deslocamento) {
            this.neadDescription = "Paciente contraindicado para Internação Domiciliar";
            return; // Para aqui se for contraindicado
          }

          // Segunda verificação: condições que levam a Internação Domiciliar 24h ou 12h
          const condicoes24h = [
            this.nead.nead.alimentacao_parenteral_NEAD.id === 1,
            this.nead.nead.aspiracao_traqueal_NEAD.id === 1,
            this.nead.nead.ventilacao_NEAD.id === 1,
            this.nead.nead.medicacao_parenteral_NEAD.id === 1
          ];

          const condicoes12h = [
            this.nead.nead.alimentacao_parenteral_NEAD.id === 2,
            this.nead.nead.aspiracao_traqueal_NEAD.id === 2,
            this.nead.nead.ventilacao_NEAD.id === 2,
            // this.nead.nead.medicacao_parenteral_NEAD.id === 2
          ];

          if (condicoes24h.some(condicao => condicao)) {
            this.neadDescription = "Indicado Internação Domiciliar 24h";
            return; // Para aqui se for Internação Domiciliar 24h
          } else if (condicoes12h.some(condicao => condicao)) {
            this.neadDescription = "Indicado Internação Domiciliar 12h";
          } else {
            this.neadDescription = "Paciente contraindicado para Atenção Domiciliar";
          }

          // Terceira verificação: pontuação total (apenas se foi classificado como 12h ou menos)
          if (this.neadDescription === "Indicado Internação Domiciliar 12h" || this.neadDescription === "Paciente contraindicado para Atenção Domiciliar") {
            const pontuacoes = [
              this.nead.nead.nutricao_NEAD.pontuacao,
              this.nead.nead.alimentacao_medicacao_NEAD.pontuacao,
              this.nead.nead.katz_NEAD.pontuacao,
              this.nead.nead.internacao_NEAD.pontuacao,
              this.nead.nead.apiracao_NEAD.pontuacao,
              this.nead.nead.lesao_NEAD.pontuacao,
              this.nead.nead.medicacao_NEAD.pontuacao,
              this.nead.nead.exercicio_ventilatorio_NEAD.pontuacao,
              this.nead.nead.oxigenio_NEAD.pontuacao,
              this.nead.nead.nivel_conciencia_NEAD.pontuacao
            ].filter(value => value !== null && value !== undefined);

            const pontuacaoTotalNead = pontuacoes.reduce((sum, value) => sum + value, 0);

            // Definindo a complexidade baseada na pontuação total
            if (pontuacaoTotalNead > 17) {
              this.neadDescription = "Indicado Internação Domiciliar 24h";
            } else if (pontuacaoTotalNead >= 12 && pontuacaoTotalNead <= 17) {
              this.neadDescription = "Indicado Internação Domiciliar 12h";
            } else if (pontuacaoTotalNead >= 6 && pontuacaoTotalNead <= 11) {
              this.neadDescription = "Indicado SAD";
            } else if (pontuacaoTotalNead <= 5) {
              this.neadDescription = "Indicado TED";
            }

            this.pontuacaoTotalNead = `${pontuacaoTotalNead}`;
          }
        } else {
          this.nead = null;
          this.neadDescription = "Não realizado";
        }
      } catch (error) {
        console.error("Erro ao buscar os dados da NEAD:", error);
      }
    },
    getPpsDescription(pontuacao) {
      if (pontuacao === 0) {
        return "Morte";
      } else if (pontuacao === 10) {
        return "Elegível Internação 24 horas";
      } else if (pontuacao === 20) {
        return "Elegível Internação 24 horas";
      } else if (pontuacao === 30) {
        return "Elegível Internação 12 horas";
      } else if (pontuacao >= 40) {
        return "Não Elegível para Internação Domiciliar";
      }
      return "";
    },
    formatDate(dateString) {
      if (!dateString) return '';
      const [year, month, day] = dateString.split('-');
      return `${day}/${month}/${year}`;
    },
    formatPhone(phone) {
    if (!phone) return '';
    const cleaned = ('' + phone).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{2})(\d{4,5})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phone;
    },
    getItemNome(item) {
      if (item.taxa) return item.taxa.taxa.nome;
      if (item.equipamento) return item.equipamento.equipamento.nome;
      if (item.procedimento) return item.procedimento.procedimento.nome;
      return 'Nome não disponível';
    },
  },
 mounted() {
   this.getPatientDetails().then(() => {
      this.getPatientEquipamento();
      this.getPacote().then(() => {
        if (this.pacoteId) {
          this.getPps();
          this.getAbemid();
          this.getItensDoPacote();
          this.getCaptacao();
          this.getCasa();
          this.getNead();
        }
      });
    });
  },
};
</script>

<style scoped>
.text {
  color: red;
}
.grey--text {
  background-color: grey !important;
}
</style>
