<template>
  <div class="pa-4">
    <v-form @submit.prevent="localOnSubmit" ref="formRef">
      <div v-if="isLoadingData" class="loading-container">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <div v-else>
        <div class="grid-container">
          <v-row>
            <v-col cols="12">
              <v-row v-for="equipamento in filteredEquipamentoOptions" :key="equipamento.id" class="d-flex align-center equipamento-row">
                <v-col cols="1" class="checkbox-col mb-4">
                  <v-checkbox
                    v-model="equipamento.selecionado"
                    :value="true"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="4" class="equipamento-nome-col">
                  <v-text-field
                    :value="equipamento.nome"
                    readonly
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="3" class="disponibilidade-col">
                  <v-select
                    dense
                    :items="disponibilidadeOptions"
                    item-text="nome"
                    item-value="id"
                    v-model="equipamento.disponibilidade"
                    outlined
                    hide-details
                    :disabled="!equipamento.selecionado"
                  >
                    <template v-slot:label>
                      <span>Disponibilidade <span style="color: red;">*</span></span>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="2" v-if="equipamento.disponibilidade === 3" class="mt-5">
                  <v-text-field
                    v-model="equipamento.data_requisicao"
                    label="Dt Requisição"
                    type="date"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="2" v-if="equipamento.disponibilidade === 3" class="mt-5">
                  <v-text-field
                    v-model="equipamento.data_prevista_implantacao"
                    label="Dt Prev. Implant."
                    type="date"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="2" v-if="equipamento.disponibilidade === 2" class="mt-5">
                  <v-text-field
                    v-model="equipamento.data_inclusao_orcamento"
                    label="Dt Orçamento"
                    type="date"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <v-row class="justify-end dense">
          <v-col class="text-end">
            <v-tooltip top :disabled="isFormValid">
              <template v-slot:activator="{ on, attrs }">
                <span v-on="on" v-bind="attrs">
                  <ConfirmButton
                    :color="isFormValid ? 'success' : 'grey'"
                    :loading="isSubmitting"
                    :disabled="!isFormValid || isSubmitting"
                  >
                    Salvar
                  </ConfirmButton>
                </span>
              </template>
              <span>Preencha todos os campos obrigatórios (*) para habilitar o botão.</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </div>
    </v-form>
  </div>
</template>

<script>
import api from '@/http';
import UtilsFunc from '../../../../../service/utilsFunc';
import ConfirmButton from '../../../../ConfirmButton.vue';

const { withCRUDUtils } = UtilsFunc;

export default {
  name: "NovoEquipamento",
  data: () => withCRUDUtils({
    isSubmitting: false,
    isLoadingData: true,
    requiredField: [e => (e !== null && e !== undefined && e !== '') || 'Obrigatório'],
    disponibilidadeOptions: [],
    equipamentoOptions: [],
    equipamentoporoperadora: [],
  }),
  components: { ConfirmButton },
  props: {
    onSubmit: Function,
    loading: Boolean,
    paciente_id: Number,
    operadora_id: Number,
  },
  computed: {
    isFormValid() {
      // Verifica se pelo menos um equipamento foi selecionado e se a disponibilidade foi escolhida
      return this.equipamentoOptions.some(equipamento => equipamento.selecionado && equipamento.disponibilidade);
    },
    filteredEquipamentoOptions() {
      const equipamentoPorOperadoraIds = this.equipamentoporoperadora.map(e => e.equipamento.id);
      return this.equipamentoOptions.filter(equipamento => equipamentoPorOperadoraIds.includes(equipamento.id));
    },
  },
  methods: {
    async localOnSubmit() {
      const validation = this.$refs.formRef.validate();
      if (!validation) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios!");
        return;
      }
      const data = new Date();
      data.setMinutes(data.getMinutes() - data.getTimezoneOffset());
      const today = data.toISOString().split('T')[0];
      // console.log(today);
      
      const selectedEquipments = this.filteredEquipamentoOptions.filter(equipamento => equipamento.selecionado);

      for (const equipamento of selectedEquipments) {
        if (equipamento.disponibilidade === 3) {
          if (!equipamento.data_requisicao || !equipamento.data_prevista_implantacao) {
            this.$toast.error("Data de Requisição e Data Prevista da Instalação são obrigatórias para esta disponibilidade de Requisição.");
            return;
          }

          if (equipamento.data_requisicao < today) {
            this.$toast.error("Data de Requisição não pode ser anterior a hoje.");
            return;
          }

          if (equipamento.data_prevista_implantacao < today) {
            this.$toast.error("Data Prevista de Instalação não pode ser anterior a hoje.");
            return;
          }
        }
        if (equipamento.disponibilidade === 2) {
          if (!equipamento.data_inclusao_orcamento || equipamento.data_inclusao_orcamento == "") {
            this.$toast.error("Data da Inclusão no Orçamento é obrigatório para esta disponibilidade.");
            return;
          }

          if (equipamento.data_inclusao_orcamento < today || equipamento.data_inclusao_orcamento > today) {
            this.$toast.error("Data da Inclusão no Orçamento não pode ser anterior e nem posterior a hoje.");
            return;
          }
        }
      }

      this.isSubmitting = true;
      const field = this.filteredEquipamentoOptions
        .filter(equipamento => equipamento.selecionado)
        .map(equipamento => ({
          paciente: this.paciente_id,
          nome_equipamento: equipamento.id,
          operadora: this.operadora_id,
          disponibilidade: equipamento.disponibilidade,
          data_inclusao_orcamento: equipamento.disponibilidade === 2 ? equipamento.data_inclusao_orcamento : null,
          data_requisicao: equipamento.disponibilidade === 3 ? equipamento.data_requisicao : null,
          data_prevista_implantacao: equipamento.disponibilidade === 3 ? equipamento.data_prevista_implantacao : null,
        }));
      // console.log('enviado --> ', field);
      await this.onSubmit(field).finally(() => {
        this.isSubmitting = false; 
      });
    },
    async getDisponibilidade() {
      const LOADING_NAME = 'get';
      this.setLoading(LOADING_NAME);
      try {
        const { data } = await api.get('/uteis/disponibilidades/');
        // Filtrar apenas as disponibilidades com ID 3 reauisitar e 2 para orcamento
        this.disponibilidadeOptions = data.filter(item => [3, 2].includes(item.id));
        // console.log('Disponibilidade --> ', this.disponibilidadeOptions);
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async getNomeEquipamento() {
      const LOADING_NAME = 'get';
      this.setLoading(LOADING_NAME);
      try {
        const { data } = await api.get('/equipamentos/nomeequipamentos/');
        this.equipamentoOptions = data.filter(item => item.ativo).map(equipamento => ({
          ...equipamento,
          selecionado: false,
          disponibilidade: 2, // Preseleciona a disponibilidade com ID 2 para orcamento
        })).sort((a, b) => {
            if (a.nome < b.nome) return -1;
            if (a.nome > b.nome) return 1;
            return 0;
        });
        // console.log('Nome do Equipamento --> ', this.equipamentoOptions);
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async getEquipamentoPorOperadora() {
      const LOADING_NAME = 'get';
      this.setLoading(LOADING_NAME);
      try {
        const { data } = await api.get(`/operadoras/operadora-equipamentos/?operadora=${this.operadora_id}`);
        this.equipamentoporoperadora = data.filter(item => item.ativo);
        // console.log('Nome do equipamentoporoperadora --> ', this.equipamentoporoperadora);
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async fetchData() {
      this.isLoadingData = true;
      await Promise.all([this.getDisponibilidade(), this.getNomeEquipamento(), this.getEquipamentoPorOperadora()]);
      this.isLoadingData = false;
    },
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    equipamentoOptions: {
      handler(newEquipamentoOptions) {
        const data = new Date();
        data.setMinutes(data.getMinutes() - data.getTimezoneOffset());
        const today = data.toISOString().split('T')[0];
        newEquipamentoOptions.forEach(equipamento => {
          if (equipamento.disponibilidade === 2) {
            equipamento.data_inclusao_orcamento = today;
          } else if (equipamento.disponibilidade === 3) {
            equipamento.data_inclusao_orcamento = null;
          }
        });
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.grid-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.equipamento-row {
  align-items: center;
}

.checkbox-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.equipamento-nome-col,
.disponibilidade-col {
  display: flex;
  align-items: center;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Ajuste conforme necessário */
}
</style>
