<template>
  <div class="pa-4" dense>
    <v-form @submit.prevent="localOnSubmit" ref="formRef">
      <v-row dense >
        <v-col cols="2">
          <v-text-field dense label="ID" v-model="localEquipamento.id" disabled outlined ></v-text-field>
        </v-col>
        <v-col cols="5">
          <v-text-field dense label="Nome do Equipamento" v-model="localEquipamento.nome_equipamento.nome" disabled outlined ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-select dense outlined label="Status" v-model="localEquipamento.ativo" :items="ativoOptions" item-value="value" item-text="nome" :disabled="!isAtivoEnabled"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-text-field dense label="Disponibilidade Atual" v-model="originalDisponibilidade" outlined disabled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row desen >
        <v-col cols="3">
          <v-select dense label="Nova Disponibilidade" v-model="localEquipamento.disponibilidade.id" :items="computedDisponibilidade" item-value="id" item-text="nome" outlined :loading="loadingDisponibilidade"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-text-field dense  label="Data Inlusão Orçamento" v-model="localEquipamento.data_inclusao_orcamento" type="date" outlined :disabled="!isAtivoEnabled"></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field dense  label="Data de Requisição" v-model="localEquipamento.data_requisicao" type="date" outlined :disabled="!isRequisicaoEnabled"></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field dense  label="Data Prevista de Instalação" v-model="localEquipamento.data_prevista_implantacao" type="date" outlined  :disabled="!isRequisicaoEnabled" ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field dense  label="Data da Solicitação de Troca" v-model="localEquipamento.data_solicitacao_retirada" type="date" outlined :disabled="!isTrocaEnabled"></v-text-field>
        </v-col>
        <v-col cols="9">
          <v-textarea dense label="Observação" v-model="localEquipamento.obs" outlined ></v-textarea>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="text-end">
          <ConfirmButton color="success" :loading="isSubmitting">
            Salvar
          </ConfirmButton>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import api from '@/http'
import ConfirmButton from '../../../../ConfirmButton.vue';
import UtilsFunc from '../../../../../service/utilsFunc'

const { withCRUDUtils } = UtilsFunc

export default {
  name: "EditarEquipamento",
  data() {
    return withCRUDUtils({
      disponibilidade: [],
      isSubmitting: false,
      loadingDisponibilidade: true,
      localEquipamento: { ...this.equipamento },
      initialDataSolicitacaoRetirada: this.equipamento?.data_solicitacao_retirada || '',
      initialDataRequisicao: this.equipamento?.data_requisicao || '',
      initialDataImplantacao: this.equipamento?.data_prevista_implantacao || '',
      initialOrcamento: this.equipamento?.data_inclusao_orcamento || '',
      dataSolicitacaoRetiradaChanged: false,
      dataRequisicaoChanged: false,
      dataImplantacaoChanged: false,
      dataOrcamentoChanged: false,
    });
  },
  components: { ConfirmButton },
  props: {
    onSubmit: Function,
    loading: Boolean,
    paciente_id: Number,
    ativoOptions: {
      type: Array,
      default: () => [
        { value: true, nome: 'Ativo' },
        { value: false, nome: 'Inativo' },
      ],
    },
    equipamento: { type: Object, default: null },
  },
  computed: {
    originalDisponibilidade() {
      return this.equipamento.disponibilidade.nome;
    },
    isAtivoEnabled() {
      return [2].includes(this.localEquipamento.disponibilidade.id);
    },
    isRequisicaoEnabled() {
      return [3].includes(this.localEquipamento.disponibilidade.id);
    },
    isTrocaEnabled() {
      return [4].includes(this.localEquipamento.disponibilidade.id);
    },
    computedDisponibilidade() {
      const currentDisponibilidade = this.disponibilidade.find(d => d.id === this.localEquipamento.disponibilidade);
      const filtered = this.disponibilidade.filter(d => [3, 2, 4].includes(d.id));
      if (currentDisponibilidade && !filtered.some(d => d.id === currentDisponibilidade.id)) {
        filtered.push(currentDisponibilidade);
      }
      return filtered;
    },
  },
  methods: {
    async localOnSubmit() {
      const validation = this.$refs.formRef.validate();
      if (!validation) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios!");
        return;
      }

      const today = new Date().toISOString().split('T')[0]; // Obtém a data de hoje no formato YYYY-MM-DD

      if (this.localEquipamento.disponibilidade.id === 3) {
        if (!this.localEquipamento.data_requisicao || !this.localEquipamento.data_prevista_implantacao || this.localEquipamento.data_requisicao == "" || this.localEquipamento.data_prevista_implantacao == "") {
          this.$toast.error("Data de Requisição e Data Prevista da Instalação são obrigatórias para esta disponibilidade.");
          return;
        }

        if (this.dataRequisicaoChanged && (this.localEquipamento.data_requisicao < today || this.localEquipamento.data_requisicao > today)) {
          this.$toast.error("Data de Requisição não pode ser anterior e nem posterior a hoje.");
          return;
        }

        if (this.dataImplantacaoChanged && this.localEquipamento.data_prevista_implantacao < today) {
          this.$toast.error("Data Prevista de Instalação não pode ser anterior a hoje.");
          return;
        }
      }

      if (this.localEquipamento.disponibilidade.id === 4) {
        if (!this.localEquipamento.data_solicitacao_retirada || this.localEquipamento.data_solicitacao_retirada == "") {
          this.$toast.error("Data de Solicitação de retirada é obrigatório para esta disponibilidade.");
          return;
        }

        if (this.dataSolicitacaoRetiradaChanged && (this.localEquipamento.data_solicitacao_retirada < today || this.localEquipamento.data_solicitacao_retirada > today)) {
          this.$toast.error("Data de Solicitação de retirada não pode ser anterior e nem posterior a hoje.");
          return;
        }
      }

      if (this.localEquipamento.disponibilidade.id === 2) {
        if (!this.localEquipamento.data_inclusao_orcamento || this.localEquipamento.data_inclusao_orcamento == "") {
          this.$toast.error("Data da Inclusão no Orçamento é obrigatório para esta disponibilidade.");
          return;
        }

        if (this.dataOrcamentoChanged && (this.localEquipamento.data_inclusao_orcamento < today || this.localEquipamento.data_inclusao_orcamento > today)) {
          this.$toast.error("Data da Inclusão no Orçamento não pode ser anterior e nem posterior a hoje.");
          return;
        }
      }

      this.isSubmitting = true; // Ativar o loading
      const fields = {
        ...this.localEquipamento,
        disponibilidade: this.localEquipamento.disponibilidade.id,
        ativo: Boolean(this.localEquipamento.ativo),
        data_inclusao_orcamento: this.localEquipamento.data_inclusao_orcamento || null,
        data_requisicao: this.localEquipamento.data_requisicao || null,
        data_prevista_implantacao: this.localEquipamento.data_prevista_implantacao || null,
        data_solicitacao_retirada: this.localEquipamento.data_solicitacao_retirada || null,
      };
      // console.log(fields);
      await this.onSubmit(fields).finally(() => {
        this.isSubmitting = false; // Desativar o loading
      });
    },
    async getDisponibilidade() {
      this.loadingDisponibilidade = true;
      try {
        const { data } = await api.get(`/uteis/disponibilidades/`);
        this.disponibilidade = data
        // console.log(this.disponibilidade)
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.loadingDisponibilidade = false;
      }
    },
    applyDateFieldRules(disponibilidadeId) {
      const today = new Date().toISOString().substr(0, 10);
      if (disponibilidadeId === 4) {
        this.localEquipamento.data_solicitacao_retirada = this.initialDataSolicitacaoRetirada || today;
      } else {
          this.localEquipamento.data_solicitacao_retirada = this.initialDataSolicitacaoRetirada;
        }
      if (disponibilidadeId === 2) {
        this.localEquipamento.data_inclusao_orcamento = this.initialOrcamento || today;
      } else {
          this.localEquipamento.data_inclusao_orcamento = this.initialOrcamento;
        }
      if (disponibilidadeId === 3) {
        this.localEquipamento.data_requisicao = this.initialDataRequisicao || today;
        this.localEquipamento.data_prevista_implantacao = this.initialDataImplantacao || today;
      } else {
          this.localEquipamento.data_requisicao = this.initialDataRequisicao;
          this.localEquipamento.data_prevista_implantacao = this.initialDataImplantacao;
        }
    },
  },
  watch: {
    equipamento: {
      immediate: true,
      handler(newVal) {
        this.localEquipamento = { ...newVal };
        this.initialDataSolicitacaoRetirada = newVal.data_solicitacao_retirada || '';
        this.initialDataRequisicao = newVal.data_requisicao || '';
        this.initialDataImplantacao = newVal.data_prevista_implantacao || '';
        this.initialOrcamento = newVal.data_inclusao_orcamento || '';
        
      },
    },
    'localEquipamento.disponibilidade.id': {
        immediate: true,
        handler(newVal) {
          this.applyDateFieldRules(newVal);
        }
      },
      'localEquipamento.data_solicitacao_retirada': function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataSolicitacaoRetiradaChanged = true;
      }
    },
    'localEquipamento.data_requisicao': function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataRequisicaoChanged = true;
      }
    },
    'localEquipamento.data_prevista_implantacao': function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataImplantacaoChanged = true;
      }
    },
    'localEquipamento.data_inclusao_orcamento': function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataOrcamentoChanged = true;
      }
    },
  },
  mounted() {
    this.getDisponibilidade();
  }
}
</script>