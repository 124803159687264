<!-- Tela da GEOLOCALIZAÇÃO do paciente -->
<template>
  <div class="px-4">
    <v-row>
      <v-col cols="12" lg="6">
        <v-data-table :loading="isLoading('get:location')" loading-text="Carregando Informações...." :items="tableData"
          :headers="headers" :search="search" v-model="selectedRows" single-select @click:row="updateMapMarker">
          <template v-slot:top>
            <v-row>
              <v-col lg="5" sm="12">
                <v-text-field dense outlined hide-details v-model="search" label="Pesquisar" class="mx-0 mt-2"
                  append-icon="mdi-magnify" clearable />
              </v-col>
              <v-col class="text-end" sm="12" lg="7">
                <!-- Botão de Atualizar a tela -->
                <v-btn class="mr-4 elevation-0" text @click="atualizar">
                  <v-icon left>mdi-reload</v-icon>atualizar página
                </v-btn>
                <!-- Botão para cadastrara nova geolocalização-->
                <v-btn color="success" class="elevation-0" @click="dialog.create = true">
                  <v-icon left>mdi-plus</v-icon>NOVO
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.ativa`]="{ item }">
            <!-- Cor do campo ATIVO e INATIVO -->
            <v-chip class="ma-2" :color="item.ativa ? 'blue' : 'grey'">
              <span class="white--text">{{ item.ativa ? 'Ativo' : 'Inativo' }}</span>
            </v-chip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="onSelectRow(item, 'update')">
                  <v-icon color="green">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Clique para editar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" lg="6" v-if="currentMark">
        <div class="map-container" viewOnly :key="`${currentMark.lat}-${currentMark.lng}`">
          <MyMap :marca="currentMark" />
        </div>
      </v-col>
      <!-- Para cadastrar uma nova localização -->
      <v-dialog v-if="dialog.create" v-model="dialog.create" max-width="800">
        <v-card>
          <v-card-title class="blod_color">
            Nova geolocalização
            <v-spacer />
            <!-- Botão e ícone de fechar a tela -->
            <v-btn icon @click="dialog.create = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <!-- Abre a pagina GeolocationForm.vue -->
            <GeolocationForm :currentItem="newItem" :onSubmit="newLocation" :loading="isLoading('post:location')"
              @change="e => newItem = e" />
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Para editar uma localização -->
      <v-dialog v-if="dialog.update" v-model="dialog.update" max-width="800">
        <v-card>
          <!-- Mostra a localização a ser editada e o nome que foi dado para ela  -->
          <v-card-title class="blod_color"> 
              Editar geolocalização
            <v-spacer />
            <!-- Botão e ícone de fechar a tela -->
            <v-btn icon @click="dialog.update = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <!-- Abre a pagina GeolocationForm.vue -->
            <GeolocationForm :currentItem="selectedRow" :onSubmit="updateLocation" :loading="isLoading('put:location')"
              @change="e => selectedRow = e" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import api from '@/http'
//var key = `key=AIzaSyAfrtV0BQ5k2GUJCgMaTsneZJ7XpmdRZeI`
//var link = 'https://maps.googleapis.com/maps/api/geocode/json?'
import UtilsFunc from '../../../../service/utilsFunc'
import GeolocationForm from './GeolocationForm.vue'
import MyMap from '../../../uteis/myMap.vue'
import './../../../../assets/css/styles.css';


const { withCRUDUtils } = UtilsFunc

export default {
  name: 'Geolocalizacao',
  data: () => withCRUDUtils({
    refresh: false,
    marca: {},
    currentMark: null,
    selectedRows: [],
    newItem: {
      ativa: 1
    },
    headers: [
      { text: 'Editar localização', value: 'actions', align: 'center', width: 80 },
      { text: 'Id', value: 'id', width: 40 },
      { text: 'Nome', value: 'nome', align: 'center', width: 100 },
      { text: 'Latitude', value: 'lat', align: 'center', width: 80 },
      { text: 'Longitude', value: 'lng', align: 'center', width: 80 },
      { text: 'Status', value: 'ativa', align: 'center', width: 80 },
    ]
  }),
  components: {
    GeolocationForm,
    MyMap
  },
  props: {
    currentItem: {
      type: Object
    },
    onUpdate: Function
  },
  methods: {
    updateMapMarker(item) {
      if (item && item.id) {
        this.getLocations();
        this.currentMark = {
          lat: item.lat,
          lng: item.lng,
          title: item.nome
        };
      }
    },
    async getLocations() {
      const LOADING_NAME = 'get:location'
      this.setLoading(LOADING_NAME)
      try {
        const { data } = await api.get(`pacientes/geolocalizacoes/?paciente=${this.currentItem.id}`)
        this.tableData = data
        const [activeLocation] = data
        if (activeLocation) {
          const { lat, lng } = activeLocation
          this.marca = {
            lat,
            lng,
            title: `${lat}, ${lng}`
          }
        }
        this.refresh = true
      } catch (error) {
        this.$errorHandler(error)
        this.$toast.error(`Desculpe. Aconteceu algum erro! ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    createMark(item) {
      this.refresh = false
      this.marca = { lat: item.lat, lng: item.lng, title: `${item.lat} - ${item.lng}` }
      setTimeout(() => {
        this.refresh = true
      }, 100)
    },
    async newLocation() {
      const LOADING_NAME = 'post:location'
      this.setLoading(LOADING_NAME)
      try {
        const fields = {
          ...this.newItem,
          ativa: Boolean(this.newItem.ativa),
          paciente: this.currentItem.id
        }
        await api
          .post('pacientes/geolocalizacoes/', fields)
        await this.getLocations()
        this.onUpdate()
        this.$toast.success('Localização Inserida com Sucesso!')
        this.newItem = {
          ativa: 1
        }
        this.dialog.create = false
        this.atualizar()
      } catch (error) {
        this.$errorHandler(error)
        this.$toast.error(`Desculpe. Aconteceu algum erro! ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async updateLocation() {
      const LOADING_NAME = 'put:location'
      this.setLoading(LOADING_NAME)
      try {
        await api
          .put(`pacientes/geolocalizacao/${this.selectedRow.id}/`, { ...this.selectedRow, ativa: Boolean(this.selectedRow.ativa) })
        await this.getLocations()
        this.onUpdate()
        this.$toast.success('Localização Atualizada com Sucesso!')
        this.dialog.update = false
        if (this.selectedRows.length > 0) {
          const itemId = this.selectedRows[0].id
          this.selectedRows = []
          setTimeout(() => {

            const updatedRow = this.tableData.find(r => r.id === itemId)
            this.selectedRows = [updatedRow]
          }, 10)
        }
        this.atualizar()
      } catch (error) {
        this.$errorHandler(error)
        this.$toast.error(`Desculpe. Aconteceu algum erro! ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    handleMarkChange(coord, obj) {
      this[obj] = { ...this[obj], ...coord }
    },
    async atualizar() {
      this.search = ''
      this.getLocations().then(() => {
        const activeAddress = this.tableData.find(address => address.ativa);
        this.selectedRows = [activeAddress];
        if (activeAddress) {
          this.currentMark = {
            lat: activeAddress.lat,
            lng: activeAddress.lng,
            title: activeAddress.nome
          };
        }
      });
    },
  },
  mounted() {
    this.getLocations().then(() => {
      const activeAddress = this.tableData.find(address => address.ativa);
      this.selectedRows = [activeAddress];
      if (activeAddress) {
        this.currentMark = {
          lat: activeAddress.lat,
          lng: activeAddress.lng,
          title: activeAddress.nome
        };
      }
    });
  }
}
</script>
<style setup>
div.map-container {
  width: 100%;
  height: 600px;
}
</style>