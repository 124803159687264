<template>
  <div class="px-4 pb-4">
    <v-row dense>
      <v-col>
        <v-data-table
          :loading="!isLoaded && isLoading('get')" loading-text="Carregando Equipamentos...." :search="search" :items="equipamentos" :headers="headers" dense  >
          <template v-slot:top>
            <!-- Linha de Pesquisar, atualizar e criar nova solicitação equipamento -->
            <v-row>
              <!-- Campo de Pesquisa do endereço -->
              <v-col cols="5">
                <v-text-field dense label="Pesquisar" outlined v-model="search" class="mx-0" append-icon="mdi-magnify" clearable />
              </v-col>
              <v-col class="text-end">
                <!-- Botão de Atualizar a tela -->
                <v-btn class="mr-4 elevation-0" text  @click="atualizar" >
                  <v-icon left> mdi-reload </v-icon> atualizar página
                </v-btn>
                <!-- Botão de Criar "+ NOVO" novo endereço -->
                <v-btn color="success" class="elevation-0"  @click="dialog.create = true" >
                  <v-icon left> mdi-plus </v-icon> NOVO
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.ativo`]="{ item }">
            <v-chip class="ma-2" :color="item.ativo ? 'blue' : 'grey'">
              <span class="white--text">{{ item.ativo ? 'Ativo' : 'Inativo' }}</span>
            </v-chip>
          </template>
          <template v-slot:[`item.data_inclusao_orcamento`]="{ item }">
            <span>{{ formatDate(item.data_inclusao_orcamento) }}</span>
          </template>
          <template v-slot:[`item.data_requisicao`]="{ item }">
            <span>{{ formatDate(item.data_requisicao) }}</span>
          </template>
          <template v-slot:[`item.data_prevista_implantacao`]="{ item }">
            <span>{{ formatDate(item.data_prevista_implantacao) }}</span>
          </template>
          <template v-slot:[`item.data_implantacao`]="{ item }">
            <span>{{ formatDate(item.data_implantacao) }}</span>
          </template>
          <template v-slot:[`item.data_solicitacao_retirada`]="{ item }">
            <span>{{ formatDate(item.data_solicitacao_retirada) }}</span>
          </template>
          <template v-slot:[`item.data_retirada`]="{ item }">
            <span>{{ formatDate(item.data_retirada) }}</span>
          </template>
          <template v-slot:no-data>
            <span> Nenhum equipamento encontrado. </span>
          </template>
          <template v-slot:[`item.edit`]="{ item }">
            <span>
              <v-btn icon @click="onSelectEquipamento(item, 'update')">
                <v-icon color="green">mdi-pencil</v-icon>
              </v-btn>
            </span>
          </template>
        </v-data-table>
      </v-col>
      <!-- Novo Equipamento -->
      <v-dialog v-if="dialog.create" v-model="dialog.create" max-width="1000">
        <v-card>
          <v-card-title class="blod_color">
            Novo equipamento:
            <v-spacer />
            <!-- Botão e ícone de fechar a tela -->
            <v-btn icon @click="dialog.create = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <!-- Abre a página de solicitação de novo equipamento -->
          <v-card-text>
            <NovoEquipamento :paciente_id="paciente.id"  :operadora_id="paciente.operadora.id"  :onSubmit="saveEquipamentos" />
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Editar um Equipmento -->
      <v-dialog v-if="dialog.update" v-model="dialog.update" max-width="1000">
        <v-card>
          <v-card-title class="blod_color">
            Editar equipamento:
            <v-spacer />
            <!-- Botão e ícone de fechar a tela -->
            <v-btn icon @click="dialog.update = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <!-- Abre a página de edição de equipamento -->
          <v-card-text>
            <EditarEquipamento :equipamento="selectedEquipamento" :paciente_id="paciente.id" :onSubmit="updateEquipamento" :ativoOptions="ativoOptions"/>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import api from '@/http'
import UtilsFunc from '@/service/utilsFunc'
import NovoEquipamento from "./NovoEquipamento.vue";
import EditarEquipamento from "./EditarEquipamento.vue";

const { withCRUDUtils } = UtilsFunc

export default {
  name: "EquipamentoPaciente",
  components: { NovoEquipamento, EditarEquipamento },
  props: {
    paciente: {
      type: Object
    },
    onUpdate: Function
  },
  data() {
    return withCRUDUtils({
      equipamentos: [],
      isLoaded: false, // Nova propriedade
      dialog: {
        create: false,
        update: false
      },
      ativoOptions: [
        { nome: "Ativo", value: true },
        { nome: "Inativo", value: false },
      ],
      selectedEquipamento: null,
      headers: [
        { text: "Editar", value: "edit", width: 80 },
        { text: "Id", value: "id", width: 80 },
        { text: "Equipamento", value: "nome_equipamento.nome", width: 250 },
        { text: "Disponibilidade", value: "disponibilidade.nome" },
        { text: "Data Orçamento", value: "data_inclusao_orcamento" },
        { text: "Data da Requisição", value: "data_requisicao" },
        { text: "Data da Previsão Implantação", value: "data_prevista_implantacao" },
        { text: "Data da Implantação", value: "data_implantacao" },
        { text: "Data Solicitação Troca / Retirada", value: "data_solicitacao_retirada" },
        { text: "Data da Retirada", value: "data_retirada" },
        { text: "Observação", value: "obs" },
        { text: "Status", value: "ativo" }
      ],
    });
  },
  methods: {
    async getEquipamento() {
      const LOADING_NAME = 'get'
      this.setLoading(LOADING_NAME)
      try {
        const { data } = await api.get(`/pacientes/equipamentos/?paciente=${this.paciente.id}`)
        this.equipamentos = data
        // console.log('Equipamento do Paciente--> ', this.equipamentos)
        this.isLoaded = true 
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, false)
      }
    },
    async updateEquipamento(fields) {
      const LOADING_NAME = 'put:equipamento'
      this.setLoading(LOADING_NAME)
      try {
        // console.log('onSubmit --> ',fields)
        await api.put(`pacientes/equipamento/${fields.id}/`, fields)
        await this.getEquipamento()
        this.$toast.success('Equipamento alterado com sucesso!')
        this.dialog.update = false
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
        this.dialog.update = false
      }
    },
    async saveEquipamentos(field) {
      const LOADING_NAME = 'post:equipamento';
      this.setLoading(LOADING_NAME);
      try {
        // console.log('Aqui o que foi--> ',field)
        for (const equipment of field) {
          await api.post(`/pacientes/equipamentos/`, equipment);
        }
        await this.getEquipamento();
        this.$toast.success('Equipamento(s) salvo(s) com sucesso!');
        this.dialog.create = false;
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, false);
      }
    },
    onSelectEquipamento(equipamentos, action) {
      if (action === 'update') {
        this.selectedEquipamento = equipamentos;
        this.dialog.update = true;
      }
    },
    formatDate(isoString) {
      if (!isoString) return '';
      const date = new Date(isoString);
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset()); // Ajuste para compensar a diferença do fuso horário
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); 
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    async atualizar() {
      this.isLoaded = false;
      await this.getEquipamento();
    },

  },
  async mounted() {
    await this.getEquipamento();
  },
}
</script>

<style scoped>
.text-green {
  color: green;
}

.text-red {
  color: red;
}
</style>
