<template>
  <v-form ref="form" @submit.prevent="onLocalSubmit" v-model="valid">
    <v-text-field v-model="currentItem.nome" :rules="nameRules" placeholder="Crie um nome para essa localização" required
      clearable dense outlined>
      <template v-slot:label>
        <span>Nome da geolocalização <span style="color: red;">*</span></span>
      </template>
    </v-text-field>
    <v-row class="mb-2">
      <v-col>
        <v-btn-toggle v-model="currentItem.ativa" mandatory class="white--text">
          <v-btn :color="!currentItem.ativa ? 'error' : ''"> Inativo</v-btn>
          <v-btn :color="currentItem.ativa ? 'green' : ''"> Ativo</v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <div v-if="mark" class="mb-4 map-container" viewOnly :key="`${mark.lat}-${mark.lng}`">
      <MyMap :marca="mark" :onMarkChange="handleMarkChange" />
    </div>
    <v-row class="mt-5 mr-5 ml-5">
      <v-text-field class="mr-5" label="Latitude" v-model="currentItem.lat" :rules="latRules" clearable dense outlined />
      <v-text-field class="ml-5" label="Longitude" v-model="currentItem.lng" :rules="lngRules" clearable dense outlined />
    </v-row>
    <v-row>
      <v-col class="text-end">
        <v-tooltip top :disabled="!!(currentItem.nome && currentItem.lat && currentItem.lng)">
          <template v-slot:activator="{ on, attrs }">
            <span v-on="on" v-bind="attrs">
              <ConfirmButton :color="isFormValid ? 'success' : 'grey'" :loading="isSubmitting"
                :disabled="!isFormValid || isSubmitting"> Salvar </ConfirmButton>
            </span>
          </template>
          <span>Preencha o "Nome da geolocalização" e marque no mapa o local para habilitar o botão.</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import MyMap from '../../../uteis/myMap.vue';
import UtilsFunc from '../../../../service/utilsFunc';
import ConfirmButton from '../../../ConfirmButton.vue';


const { withCRUDUtils } = UtilsFunc

export default {
  name: "GeolocationForm",
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean
  },
  data: () => withCRUDUtils({
    isSubmitting: false,
    valid: true, // para rastrear a validade do formulário
    nameRules: [v => !!v || 'Nome é obrigatório',],
    latRules: [v => !!v || 'Latitude é obrigatória',],
    lngRules: [v => !!v || 'Longitude é obrigatória',]
  }),
  methods: {
    onLocalSubmit() {
      if (!this.$refs.form.validate()) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios.");
        return;
      }

      this.isSubmitting = true;
      this.onSubmit({
        ...this.currentItem
      }).catch((error) => {
        this.$toast.error(`Desculpe. Apresentou algum erro. ${error}`);
      }).finally(() => {
        this.isSubmitting = false; // Finalizar o envio
      });
    },
    handleMapClick(event) {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      this.$emit('onMarkChange', { lat, lng });
    },
    handleMarkChange(coord) {
      this.$emit('change', { ...this.currentItem, ...coord })
    },
  },
  components: { MyMap, ConfirmButton },
  mounted() {
    this.currentItem.ativa = true;
    this.currentItem.ativa = Number(this.currentItem.ativa)
  },
  computed: {
    isFormValid() {
      return this.currentItem.nome && this.currentItem.lat && this.currentItem.lng;
    },
    requiredAsterisk() {
      return '<span style="color: red;">*</span>';
    },
    mark() {
      const { nome, lat, lng } = this.currentItem;

      if (this.dialog.create) {
        return {
          title: nome,
          lat,
          lng
        };
      } else if (!isNaN(lat) && !isNaN(lng)) {
        return {
          title: nome,
          lat,
          lng
        };
      } else if (lat && lng && (!lat.trim() || !lng.trim() || isNaN(lat) || isNaN(lng))) {
        this.$toast.error("Coordenadas inválidas. Certifique-se de que a latitude e a longitude são números válidos.");
      }

      return {
        title: nome,
        lat,
        lng
      };
    }
  },
}
</script>
<style scoped>
div.map-container {
  height: 300px;
}
</style>