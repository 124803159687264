<template>
  <gmap-map :mapTypeId="'hybrid'" :zoom="14" @click="get_mark" :center="center" class="g-map" :options="options">

    <gmap-marker :clickable="true" :key="index" v-for="(m, index) in locationMarkers" :position="m.position"
      :title="m.title" @click="toggleInfoWindow(m)">
    </gmap-marker>
    <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen"
      @closeclick="infoWinOpen = false">
      {{ infoContent }}
    </gmap-info-window>
  </gmap-map>
</template>
<script>
export default {
  name: "Mapa",
  props: {
    marcas: {
      type: Array
    },
    marca: {
      type: Object
    },
    centralizar: {
      type: Boolean,
      default: false
    },
    read: {
      type: Boolean,
      default: true
    },
    onMarkChange: Function,
    viewOnly: Boolean

  },
  data: () => ({
    result: null,
    origin: {
    },
    center: {
      lat: -2.5005190309483756,
      lng: -44.28711062587991,
      title: ''
    },
    options: {
      zoomControl: true,
      mapTypeControl: false,
      backgroundColor: '#000000',
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true,
      styles: {
        default: [],
        hide: [
          {
            featureType: 'poi.attraction',
            stylers: [{ visibility: 'off' }],
          },
          {
            featureType: 'transit',
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }],
          },
        ],
      },
      disableDefaultUi: false
    },
    locationMarkers: [],
    locPlaces: [],
    existingPlace: null,
    usu: null,
    infoOpts: {
      content: '',
      pixelOffset: {
        width: 0,
        height: -205,
      }
    },
    infoPos: null,
    infoOpen: false,
    ultima_posicao_banco: null,
    infoContent: '',
    infoWindowPos: null,
    infoWinOpen: false,
    currentMidx: null,
    //optional: offset infowindow so it visually sits nicely on top of our marker
    infoOptions: {
      pixelOffset: {
        width: 0,
        height: -35
      }
    },
  }),
  methods: {
    initMarker(loc) {
      this.existingPlace = loc;
    },
    get_mark(event) {
      if (this.viewOnly) return
      const lat = event.latLng.lat()
      const lng = event.latLng.lng()
      const ll = { lat, lng, title: `${lat};${lng}` }
      if (this.onMarkChange) this.onMarkChange(ll)
      this.toggleInfoWindow(ll, 0)
      this.$emit('latlng', ll)
      this.addLocationMarker(ll)
    },
    addLocationMarker(event) {
      this.result = event
      this.locationMarkers = []
      this.locationMarkers.push({
        position: {
          lat: Number(event.lat),
          lng: Number(event.lng),
        }, title: event.title
      });
    },
    toggleInfoWindow(marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoContent = marker.title;
      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    get_start() {
      if (this.marca?.lat) {
        const { lat, lng, title } = this.marca
        this.center = {
          lat: Number(lat),
          lng: Number(lng),
          title
        }
        this.addLocationMarker(this.center)
        this.toggleInfoWindow({ position: this.center, title }, 0)
      }
    }
  },
  mounted() {
    this.get_start()
  }
}
</script>

<style>
.vue-map,
.g-map {
  width: 100%;
  height: 100%;
}
</style>
