<template>
  <v-container class="container">
    <v-btn color="success" depressed size="small" @click="generatePDF" >
      <v-icon left>mdi-printer</v-icon> Lista de Pacientes
    </v-btn>
  </v-container>
</template>

    
<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import UtilsFunc from "../../service/utilsFunc";
import logo from "../../assets/logo.png";
import gptw from "../../assets/GPTW_2023-2024.png"
import ona from "../../assets/ONA_Nivel_2.png"
import UtilsPDF from '../../service/utilsPDF';

const { withCRUDUtils } = UtilsFunc
export default {
  name: "Produtividade",
  data: () => withCRUDUtils({
    loading: false,
  }),
  props: {
    displayedData: { type: Array, default: () => [] },
    username: String,
  },
  methods: {
    formatarData(data) {
      return moment(data).format('DD/MM/YYYY');
    },
    formatarValor(valor) {
      return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valor);
    },
    drawHeader(doc) {
      try {
        doc.addImage(logo, 10, 8, 28, 28);
        doc.addImage(ona, 160, 10, 15, 15);
        doc.addImage(gptw, 185, 10, 15, 15);
      } catch (error) {
        this.$toast.error("Falha ao adicionar a imagem ao PDF: (L-42):", error);
      }
      doc.setFont("helvetica", "normal");
      doc.setDrawColor(57, 106, 159);
      doc.setLineWidth(1);
      doc.line(5, 5, 205, 5);

      doc.setDrawColor(0, 0, 0);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(18); // Tamanho da fonte, ajuste conforme necessário
      doc.text("São Luís Home Care", 105, 15, { align: "center" });
      doc.setFont("helvetica", "normal");
      doc.setFontSize(16);
      doc.text(`Relação de Pacientes`, 105, 28, { align: "center" });

      moment.locale("pt-br"); // garantir que seja horario de brasília independente do dispositivo

      const currentDate = new Date();
      const hour = moment(currentDate).format("HH:mm");

      doc.setFontSize(8);
      doc.text(`Gerado por: ${this.username}`, 200, 28, { align: "right"});
      doc.text("Data e Hora da Impressão:", 200, 32, { align: "right" });
      doc.text(`${UtilsPDF.formatDate(currentDate)} às ${hour}`, 200, 36, {
        align: "right",
      });

    },
    generatePDF() {
      this.loading = true;
      this.$toast.success(`O documento está sendo preparado. Aguarde, por favor.`);

      const doc = new jsPDF({ compress: true });
      this.drawHeader(doc);
      // Organizando os dados
      const dadosOrdenados = this.displayedData
        .slice() // Cria uma cópia para não alterar o original
        .sort((a, b) => {
          // Primeiro por convênio
          if (a.operadora.nome < b.operadora.nome) return -1;
          if (a.operadora.nome > b.operadora.nome) return 1;
          // Em seguida, por nome do paciente dentro do mesmo convênio
          return a.nome.localeCompare(b.nome);
        });

      const corpoTabela = dadosOrdenados.map(item => [
        item.id,
        item.operadora.nome,
        item.nome,
        item.modalidade_sessao.map(mod => mod.nome).join(", ") ? item.modalidade_sessao.map(mod => mod.nome).join(", ") : 'Sem Orçamento',
        this.formatarData(item.data_nascimento),
        item.liminar ? 'SIM': 'NÃO',
        item.paliativo ? 'SIM': 'NÃO',
      ]);
      corpoTabela.push([
        '', '', '', '', '', '',
      ]);

      // Adiciona a tabela ao documento
      doc.autoTable({
        head: [['ID', 'Convênio', 'Paciente', 'Modalidade', 'Data Nascimento', 'Liminar', 'Paliativo']],
        body: corpoTabela,
        startY: 40,
        didParseCell: function(data) {
          // Estilizando a última linha (Total)
          if (data.row.index === corpoTabela.length - 1) {
            if (data.column.index === 6) { // Última coluna
              data.cell.styles.halign = 'right'; // Alinha à direita
              data.cell.styles.fontStyle = 'bold';
            }
          }
        }
      });

      const quantidadeOrçamentos = dadosOrdenados.length;
      const fraseQuantidade = `Quantidade de Pacientes: ${quantidadeOrçamentos}`;

      // Posição Y: logo abaixo da última linha da tabela
      const posY = doc.autoTable.previous.finalY + 5; // Ajuste o espaçamento conforme necessário

      // Posição X: alinhado à direita
      const pageWidth = doc.internal.pageSize.getWidth();
      const textSize = doc.getStringUnitWidth(fraseQuantidade) * doc.internal.getFontSize() / doc.internal.scaleFactor;
      const posX = pageWidth - textSize - 27; // 10 é a margem direita, ajuste conforme necessário

      doc.setFont("helvetica", "normal");
      doc.setFontSize(10); // Ajuste o tamanho da fonte conforme necessário
      doc.text(fraseQuantidade, posX, posY);


      UtilsPDF.addPageNumbers(doc);
      const filename = `Relação de Pacientes.pdf`;
      doc.save(filename);
      this.$toast.success('O download do relatório foi realizado com sucesso!');
    },

  },
};
</script>
<style scoped>
.container {
  display: flex;
  width: 100%;
  gap: 0.2rem;
  padding: 0;
  flex-direction: column;
}
</style>