<!-- Tela dos Endereços cadastrados para o paciente selecioando -->
<template>
  <div class="pa-4">
    <v-data-table :headers="headers" :items="tableData" :loading="isLoading('get:address')" loading-text="Carregando Informações...." :sort-by.sync="defaultSort.name" :sort-desc.sync="defaultSort.isDesc" :search="search">
      <template v-slot:top>
        <!-- Linha de Pesquisar, atualziar e criar novo endereço -->
        <v-row>
          <!-- Campo de Pesquisa do endereço -->
          <v-col cols="5">
            <v-text-field dense label="Pesquisar" outlined v-model="search"  class="mx-0" append-icon="mdi-magnify" clearable/>
          </v-col>
          <v-col class="text-end">
            <!-- Botão de Atualizar a tela -->
            <v-btn class="mr-4 elevation-0" text @click="getAddress">
              <v-icon left> mdi-reload </v-icon> atualizar página
            </v-btn>
            <!-- Botõa de Criar "+ NOVO" novo endereço -->
            <v-btn color="success" class="elevation-0" @click="dialog.create = true">
              <v-icon left> mdi-plus </v-icon> NOVO
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.ativo`]="item">
        <!-- Cor do campo ATIVO e INATIVO -->
        <v-chip class="ma-2" :color="item.item.ativo ? 'blue' : 'grey'">
          <span class="white--text">{{ item.item.ativo ? 'Ativo' : 'Inativo' }}</span>
        </v-chip>
      </template>
      <template v-slot:[`item.acoes`]="{ item }">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="onSelectRow(item, 'update')">
                        <v-icon color="green">mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <span>Clique para editar</span>
            </v-tooltip>
        </template>
    </v-data-table>
    <v-dialog v-if="dialog.create" v-model="dialog.create" max-width="800">
      <v-card>
        <v-card-title class="blod_color">
            Novo endereço:
          <v-spacer />
          <!-- Botão e ícone de fechar a tela -->
          <v-btn icon @click="dialog.create = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <!-- Abre a página de dados do endereço AddressForm.vue -->
        <v-card-text>
          <AddressForm :currentItem="newItem" @onUpdateItem="e => newItem = e" :onSubmit="postAddress"
            :loading="isLoading('post:address')" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-if="dialog.update" v-model="dialog.update" max-width="800">
      <v-card>
        <v-card-title class="blod_color">
          Editar endereço:
          <v-spacer />
          <!-- Botão e ícone de fechar a tela -->
          <v-btn icon @click="dialog.update = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <!-- Abre a página de dados do endereço AddressForm.vue -->
        <v-card-text>
          <AddressForm :currentItem="selectedRow" @onUpdateItem="e => selectedRow = e" :onSubmit="putAdress"
            :loading="isLoading('put:address')" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from '@/http'
import UtilsFunc from '../../../../service/utilsFunc';
import AddressForm from './AddressForm.vue'
import axios from 'axios'
import './../../../../assets/css/styles.css';

const { withCRUDUtils } = UtilsFunc

export default {
  name: "Enderecos",
  data: () => withCRUDUtils({
    ufs: [],
    locales: [],
    tipos: [],
    headers: [
      { text: "Editar enderço", align: "center", value: "acoes", width: 50, sortable: false },
      { text: "Tipo", value: "tipo.nome" },
      { text: "CEP", value: "cep" },
      { text: "Logradouro", value: "logradouro" },
      { text: "Nº", value: "nr" },
      { text: "Bairro", value: "bairro" },
      { text: "Complemento", value: "complemento" },
      { text: "Município", value: "municipio.localidade" },
      { text: "UF", value: "uf.sigla" },
      { text: "Status", value: "ativo" },
    ],
    newItem: { ativo: true },
    defaultSort: { isDesc: true, name: "ativo" },
  }),
  components: { AddressForm },
  props: {
    paciente: {  type: Object },
    onUpdate: Function
  },
  methods: {
    onDiscartChanges() {
      this.selectedRow = {}
    },
    async autofillAddressFromCep(cep, action) {
      const objectToUpdate = action === 'update' ? 'selectedRow' : 'newItem'
      try {
        const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
        const uf = this.ufs.find(({ sigla }) => sigla === data.uf)
        await this.getLocales(uf)
        const localidade = this.locales.find(({ localidade }) => localidade === data.localidade)
        const fullfilledItem = {
          cep,
          uf,
          localidade,
          municipio: localidade,
          bairro: data.bairro,
          complemento: data.complemento,
        }

          this[objectToUpdate] = { ...this[objectToUpdate], ...fullfilledItem }
      } catch (error) {
          this.$toast.error('CEP não encontrado')
      }
    },
    async getAddress() {
      const LOADING_NAME = 'get:address'
      this.setLoading(LOADING_NAME)
      try {
        this.loading_enderecos = true;
        let endpoint = `pacientes/enderecos/?enderecos=${this.paciente.id}`
        const { data } = await api.get(endpoint)
          this.tableData = data
      } catch (error) {
        // this.$errorHandler(error)
        this.$toast.error(`Desculpe. Aconteceu algum erro! ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async postAddress() {
      const LOADING_NAME = "post:address"
        this.setLoading(LOADING_NAME)
      try {
        const fields = {
          ...this.newItem,
          ativo: true,
          usuario: this.paciente.id
        }
        await api.post('pacientes/enderecos/', fields)
        await this.getAddress()
          this.onUpdate()
          this.$toast.success('Endereço inserido com sucesso!')
          this.dialog.create = false
          this.newItem = {}
      } catch (error) {
          this.$errorHandler(error)
      } finally {
          this.setLoading(LOADING_NAME, true)
      }
    },
    async putAdress() {
      const LOADING_NAME = "put:address"
        this.setLoading(LOADING_NAME)
      try {
        if (this.selectedRow.municipio?.id) this.selectedRow.municipio = this.selectedRow.municipio.id
        await api.put(`pacientes/endereco/${this.selectedRow.id}/`, this.selectedRow)
        await this.getAddress()
          this.onUpdate()
          this.$toast.success('Endereço atualizado com sucesso!')
          this.dialog.update = false
      } catch (error) {
          this.$errorHandler(error)
      } finally {
          this.setLoading(LOADING_NAME, true)
      }
    },
    // -----------------------        Para que serve essas duas funções????     -----------------------
    // async handleNewAddress() {
    //   const LOADING_NAME = 'post:address'
    //     this.setLoading(LOADING_NAME)
    //   try {
    //     {
    //       const { tipo, cep, uf } = this.newItem
    //       if (!tipo || !cep || !uf) throw new Error('Preencha todos os campos')
    //     }
    //     const fields = { ...this.newItem, usuario: this.paciente.id, ativo: true }
    //     await api.post('pacientes/enderecos/', fields)
    //     await this.getAddress()
    //       this.$toast.success('Endereço inseridoss com sucesso!')
    //       this.newItem = {}
    //   } catch (error) {
    //       this.$errorHandler(error)
    //   } finally {
    //       this.setLoading(LOADING_NAME, true)
    //   }
    // },
    // async handleSaveChanges() {
    //   const LOADING_NAME = 'put:address'
    //   this.setLoading(LOADING_NAME)
    //   const selectedRow = this.selectedRow
    //   try {
    //     if (this.selectedRow.municipio?.id) this.selectedRow.municipio = this.selectedRow.municipio.id
    //     this.selectedRow.ativo = true
    //     await api.put(`pacientes/endereco/${selectedRow.id}/`, selectedRow)
    //     await this.getAddress()
    //     this.$toast.success('Endereço atualizado com sucesso!')
    //     this.selectedRow = {}
    //   } catch (error) {
    //     this.$errorHandler(error)
    //   } finally {
    //     this.setLoading(LOADING_NAME, true)
    //   }
    // },
    async getTipos() {
      const { data } = await api.get('contas/tipos-enderecos/')
        this.tipos = data
    },
    async getUFS() {
      const { data } = await api.get(`uteis/uf/`)
        this.ufs = data
    },
    async getLocales(uf) {
      const { data } = await api.get(`/uteis/localidades/?uf=${uf.sigla}`)
        this.locales = data
    }
  },
  mounted() {
    this.getAddress()
    this.getTipos()
    this.getUFS()
  },
  watch: {
    'selectedRow.uf': function () {
      if (this.selectedRow.uf) this.getLocales(this.selectedRow.uf)
    }
  },
}
</script>

<style>
.address-table tr:first-child td,
tr.--editing-row td {
  padding-right: 1px !important;
  padding-left: 1px !important;
}
</style>
